<template>
  <div></div>
</template>

<script>
export default {
  data() {
    return {};
  },
  props: {},
  components: {},
  methods: {},
  mounted() {
    console.log(location.href);
  },
  created() {},
  computed: {},
  watch: {},
};
</script>

<style lang="scss" scoped>
</style>